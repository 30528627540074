/* Big tablet to 1200px (widths smaller taht the 1140px row) */
@media only screen and (max-width: 1200px) {


  .header1 {
    font-size: 30px;
    font-weight: 700;
}

.subText {
  font-size: 14px;
}


.paddingRight {
  padding-right: 0px;
}

.withMarginLarge {
  margin-left: 60px;
  margin-right: 60px;
}

   
    .heroBg{
     
        background-image:linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../../images/heroBg.jpg");
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
      
      height: 45vh;
      }
      
      .withPaddingLarge {
        padding-top: 0px;
        padding-left: 60px;
        padding-right: 60px;
        text-align: center;
    }
    
    .imgDiv {
        width: 100%;
        margin-top: 90px;
    }
    
    .testimonialBg {
        height: 70vh;
        background-color: #fff;
        box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px;
        margin-top: -60px;
        border-radius: 10px;
        margin-bottom: 100px;
    }
    .statsBg{
        background-image:linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../../images/aboutCyberTeckAcademy.png");
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
      
      height: 40vh;
      }
    
    
      .resultHeader{
        font-size: 40px;
        color: #fff;
        margin-top: 10px;
      }
    
      .statsImg{
        width: 100px;
        margin-bottom: 30px;
      }
    
      .bIcon .headerBold{
        color: #fff;
        margin-top: -20px;
        font-size: 15px;
        padding-top: 20px;
      }
    
      .modalBtn {
        position: relative;
        left: 361px;
    }








    }
    





    
    
    
    /* Small tablet to big tablet: from 768px to 1023px */
    @media only screen and (max-width: 1023px) {
    
      .header3 {
        font-size: 23px;
        font-weight: 700;
    }
    
        .header1 {
            font-size: 26px;
            font-weight: 700;
        }
        
        .imgDiv {
            width: 100%;
            margin-top: 90px;
        }
    
    .heroBg{
     
        background-image:linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../../images/heroBg.jpg");
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
      
      height: 60vh;
      }
      
      .withPaddingLarge {
        padding-top: 0px;
        padding-left: 60px;
        padding-right: 60px;
        text-align: center;
    }
    .iconParagraph {
        font-size: 14px;
    }
    
    
    .testimonialBg {
        height: 85vh;
        background-color: #fff;
        box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px;
        margin-top: -60px;
        border-radius: 10px;
        margin-bottom: 100px;
    }
    
    .statsBg{
        background-image:linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../../images/aboutCyberTeckAcademy.png");
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
      
      height: 65vh;
      }
    
    
      .testyDesc p {
        padding-left: 50px;
        padding-right: 50px;
    }

    
    .iconHeaderBold {
      font-weight: 800;
      font-size: 14px;
      padding-top: 5px;
  }

  .iconParagraphSmall {
    font-size: 13px;
    margin-top: -4px;
    line-height: 18px;
}
    
    
    .withMarginLarge {
      margin-left: 0px;
      margin-right: 0px;
    }
    

    .negativeTopMarginLargeNew {
      position: relative;
      top: -11px;
  }


  .mediumContainer {
    width: 300px !important;
}

.height300 {
  height: 395px !important  ;
}

.modalBtn {
  position: relative;
  left: 246px;
}




    
    
    }
    
    
    
    
    
    /* Small phones to small tablets: from 481px to 767px */
    @media only screen and (max-width: 767px) {
    


      h1{
        font-size: 28px;
      }
    
      h2{
        font-size: 22px;
      }
    
        p {
          font-family: 'Spectral', serif;
            letter-spacing: 1px;
            font-size: 16px;
        }
    
        .header1{
            font-size:26px;
            line-height: 31px !important;
            font-weight: 700;
            padding-right: 0px;
            padding-left: 0px;
            padding-bottom: 15px;
          
          }
    
          h3 {
            font-size: 18px;
            font-weight: bold;
        }
    .header4 {
        font-size: 18px;
        font-weight: 700;
    }
        h5 {
            font-size: 16px;
        }
    
        .withMarginLarge {
          margin-left: 0px;
          margin-right: 0px;
      }


      .negativeTop90{
        position: relative;
        top:-90px
      }
    
  .paddingRight{
    padding-right: 0px !important;
  }


  .withPaddingToCenterSubText {
    padding-right: 20px;
    padding-left: 20px;
}


.normalPaddingLR {
  padding-left: 10px;
  padding-right: 10px;
}
    
.modalBtn {
  position: relative;
  left: 46px;
}
          
    .btn{
        background-color: #3FC6EB;
        padding: 10px 10px 10px 10px;
        border-radius: 30px;
        color:#fff;
        width:120px;
        font-size: 12px;
      }
      
      
      .btn-light{
        background-color: #fff;
        padding: 10px 10px 10px 10px;
        border-radius: 30px;
        color:#000;
        width:120px;
        font-size: 12px;
      }
      
      
      .btn-ghost-light{
        border: 1px solid #fff;
        padding: 10px 10px 10px 10px;
        border-radius: 30px;
        color:#fff;
        width:120px;
        font-size: 12px;
      }
      
      .btn-ghost{
        border: 1px solid #3FC6EB;
        padding: 10px 10px 10px 10px;
        border-radius: 30px;
        color:#fff;
        width:120px;
        font-size: 12px;
      }
    
     
  .smallTextTopHeader{
    margin-bottom: -30px;
    color:#828282;
    text-transform: uppercase;
    font-size: 12px;
  }

    
      .withPaddingLarge {
        padding-top: 0px !important;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
        font-size: 18px;
    }
    
    
    .heroBg{
     
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
    
    height: auto;
    padding-bottom: 10px !important;
    height:;
    }
    
      .subTitleText {
        padding: 5px;
        color: #fff;
        margin-top: -50px;
        padding-top:4px
    }
    
    
    .imgDiv{
        margin-top: 78px;
    }
    
    .iconHeader{
        font-weight:600;
        font-size: 16px;
        margin-top: -5px;
      }
    
    
      .iconParagraph{
        font-size:14px;
      }

      .iconParagraphSmall{
        font-size:14px;
        margin-top: 1px;
        padding-right: 20px;
        padding-left: 20px;
       
      }
    
      .header3 {
        font-size: 23px;
        font-weight: 700;
    }

    .subText{
      font-size: 14px;
      padding: 0px;
      line-height: 24px;
    }
    
   .iconHeaderSmall {
    font-weight: 600;
    font-size: 14px;
}



    .testyDesc p{
        padding-left: 0px;
        padding-right: 0px;
     
     }
    
     .testifyTextBg {
        background-color: #faf4ff;
        height: auto;
        width: 90%;
        margin-top: -50px;
        padding-top: 70px;
        border-radius: 6px;
        margin-left: 40px;
    }
    
    .testifyText {
    
      padding:20px;
        font-size: 12px;
        text-align: center;
       
      }



    .testyDesc p {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 60p;
    }
    
    .testifyImage {
        width: 118px;
        position: relative;
        left: 20px;
    }
    
    .smallTopHeading {
        font: size 18px;
        font-weight: 600;
        letter-spacing: 3px;
        color: #faf4ff;
        margin-bottom: -40px;
        padding-top: 80px;
    }
    
    .testimonialBg {
        height: auto;
        background-color: #fff;
        box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px;
        margin-top: -60px;
        border-radius: 10px;
        margin-bottom: 100px;
        width: 340px;
    }
    
    
    .statsBg{
        background-image:linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../../images/aboutCyberTeckAcademy.png");
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
      
      height: 190vh;
      }
    
      .statsContainer {
        padding-right: 0px;
        padding-left: 0px;
    }
    
    .bIcon{
        margin-top:50px
    }
    
    .resultHeader {
        font-size: 40px;
        color: #fff;
        margin-top: 10px;
    }
    
    .iconCheck {
      width: 81px;
  }

  
  .iconSecure{
    width: 30px;
  }
  

  .schoolSearchButton{
    font-size: 12px !important;
  }

  .statsImg{
    width: 135px;
    margin-bottom: 30px;
  }


  .icon {
    width: 45px;
}

.introContainer{
  background-color: #fff;
  height: 444px;
  margin-top: -120px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.moveLeftDiv {
  position: relative;
  left: 2px;
  top: 35px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  padding: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
}

.checkIcon {
  width: 45px;
  position: relative;
  left: -20px;
}
.imgFull{
  margin-top:0px
}

.moveIconLeft {
  position: relative;
  left: 0px;
}
.imgFull {
  margin-top: 0px !important;
}



    }