.introImg{
    width: 500px;
}


.veTopMargin{
    position: relative;
    top: -120px;
}

.ValleyCourtBg{
    background-color: #3FC6EB;
    height: 69vh;
}

.boiteBg{
    background-color: #3FC6EB;
    height: 60vh;
}

.paddingTopMedium{
   padding-top: 60px; 
}

.customIcon{
    width:400px !important;
}


.iconMedium{
    width: 60px;
}
.moveLeftTextH{
    position: relative;
    left:-30px;
    padding-top: 5px;
}

.marginUp{
    margin-top: -75px;
}

.ceoImg{
    
}

.thirdCurved{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	padding:20px;
    background-color: #fff;
	border-bottom-left-radius:50px;
	border-top-right-radius:50px;
}






/* Big tablet to 1200px (widths smaller taht the 1140px row) */
@media only screen and (max-width: 1200px) {

}







/* Small tablet to big tablet: from 768px to 1023px */
@media only screen and (max-width: 1023px) {

}







/* Small phones to small tablets: from 481px to 767px */
@media only screen and (max-width: 767px) {
    .boiteBg{
        background-color: #3FC6EB;
        height: 69vh;
    }
}









/* Small phones: from 0 to 480px */
@media only screen and (max-width: 480px) {

    
.veTopMargin{
    position: relative;
    top: -50px;
}

}