.blueText{
    color: #3FC6EB
}

.bluebg{
    background-color: #3FC6EB;
}

a {
    color: #0274d4 
}


.mediumContainer{
    width:1100px
}

.topFooter{
   background-color: #3FC6EB; 
   padding: 30px 0 30px 0;
}

.mainFooter{
    background-color: #333333; 
   padding: 30px 0 30px 0;
   color: #fff;
}

.footer img{ 
    width: 200px
    
}

.footer, p{
    font-size: 1px;
}

.projectPics{
    width: 150px;
}

.imgFull{
   margin-top: -90px;
}

.moveIconLeft{
    position: relative;
    left:-20px;
}

.uk_check{
    position: relative;
    left:10px;
}

.projectImg img {
  width: 420px;
}

.blueBgRemodel{
    background-color: #3FC6EB;
    padding: 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	padding:20px;
	border-bottom-left-radius:20px;
	border-top-right-radius:20px
}

.grayBgRemodel{
    background-color: #929292;
    padding: 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	padding:20px;
	border-bottom-left-radius:20px;
	border-top-right-radius:20px 
}

.shadow{
    background-color: #fff;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
padding: 20px;
border-bottom-left-radius: 20px;
border-top-right-radius: 20px;
}

.marginRemoveTop{
    position: relative;
    top:-40px;
}



.custImgSizeL{
    width: 400px;
}

.custImgSizeW{
    width:400px
}

.counter{
    font-size: 80px;
    color: #fff;
    font-weight: 900;
}


/* Big tablet to 1200px (widths smaller taht the 1140px row) */
@media only screen and (max-width: 1200px) {


}







/* Small tablet to big tablet: from 768px to 1023px */
@media only screen and (max-width: 1023px) {


}






/* Small phones to small tablets: from 481px to 767px */
@media only screen and (max-width: 767px) {
    .icon-bar {
        position: fixed;
        top: 80%;
        transform: translateY(-50%);
        left: -5px;
    }

.mobImg{
    position: relative;
    top:90px !important
}




}







/* Small phones: from 0 to 480px */
@media only screen and (max-width: 480px) {


 
}



