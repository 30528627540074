/**Styles for About Us page**/
p{
  font-family: 'spectral';
    
  line-height: 26px !important;
  font-size:18px !important;
  letter-spacing:0px
}




h1,h2,h3,h4, h5{
  
  font-family: 'poppings' serif;
     
  }

  

  
  h3{
    font-size: 24px;
    font-weight:bold;
  }
  
  h4{
    font-size: 18px;
  
  }

  
  h5{
    font-size: 22px;
    line-height: 32px !important;
    font-weight: 500;
    
  }

  h6{
    font-size: 20px;
   
  }

  .header6{
    font-size: 18px;
  }


  .height300{
    height: 300px;
}

.header1{
  line-height:85px;
letter-spacing: 0px;
font-weight: 700;
font-size: 80px;
font-family: 'poppings' serif;
}

.header2{
  line-height:50px;
letter-spacing: 0px;
font-weight: 700;
font-size: 50px;
font-family: 'poppings' serif;
}


.header3{
  font-size:30px;
}


.header4{
  font-size:22px;
}



.header1.light{
  
  color: #fff;
  letter-spacing: 1px;
}




/**Mobile menu styles**/



.mobileMenuBar{
  width:100% !important;
  background-color: #000 !important;
  
}


  p, ul, li, div, nav
  {

      padding:0;
      margin:0;
  }


  body
  { 
  }


  #menu {
      overflow: auto;
      position:relative;
      z-index:2;
      height: 196px;
  }


  .parent-menu {
      
      min-width:200px;
      float:left;
  }


  #menu ul
  {
      list-style-type:none;
  }


  #menu ul li a
  {

    padding: 11px 10px;
    display: block;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    margin-top: -14px;
    padding-top: 10px;
    
  }


      #menu ul li a:hover
      {
          
      }

      #menu ul li:hover > ul {

        left: 142px;

        -webkit-transition: left 200ms ease-in;

        -moz-transition: left 200ms ease-in;

        -ms-transition: left 200ms ease-in;

        transition: left 200ms ease-in;

    }


    #menu ul li > ul {

        position: absolute;

        background-color: #333;
        padding-top: 20px;

        top: 0;

        left: -219px;

        min-width: 200px;

        z-index: -1;

        height: 100%;

        -webkit-transition: left 200ms ease-in;

        -moz-transition: left 200ms ease-in;

        -ms-transition: left 200ms ease-in;

        transition: left 200ms ease-in;

    }


    #menu ul li > ul li a:hover

    {

        background-color:#2e2e2e;

    }


.textLeft{
  position: relative;
  left:-50px;
}

.negativeTopMarginSmall{
  position: relative;
  top: -15px;
}



    .advertImg{
      width: 400px
    }




    .modalBtn{
      position: relative;
      left:60px;
      padding-top: 15px;
    }



.mobileNav{
  width: 100%;
  background-color: ;
  padding: 15px;
}

  .uk-navbar-container:not(.uk-navbar-transparent) {
    background: transparent !important;
}

.uk-navbar-dropdown {
  display: none;
  position: absolute;
  z-index: 1020;
  box-sizing: border-box;
  width: 260px;
  padding: 25px;
  background: #000 !important;
  color: #666;
  box-shadow: 0 5px 12px rgb(0 0 0 / 15%);
}


.headerBtn a{
   font-size: 12px;
   color:#fff;
}




  .logoStyle{
    max-width: 180px;
  }

  .mainNavBar{
    padding-top: 15px;
    padding-bottom: 10px;

  }



  nav {
    margin: 0 auto;
    font-family: 'Spectral', serif !important;
    padding: 10px 0;
  }
  nav ul {
    list-style: none;
    text-align: left;
    font-family: 'Spectral', serif !important;
  }
  nav ul li {
    display: flex;
    font-family: 'Spectral', serif !important;
    
  }
  nav ul li a {
    
    text-decoration: none;
    
    color: #fff !important;
    text-transform: none !important;
    font-family: 'Spectral', serif;
    margin-top: 10px;
  }
  nav ul li a,
  nav ul li a:after,
  nav ul li a:before {
    transition: all 0.5s;
  }
  nav ul li a:hover {
    color: #fff;
    text-decoration: none;
  }

  .appntmentButton{
    text-decoration: none;
    
    color: #fff !important;
    text-transform: none !important;
    font-family: 'Spectral', serif;
  }
  
  /* Keyframes */
  @-webkit-keyframes fill {
    0% {
      width: 0%;
      height: 1px;
    }
    50% {
      width: 100%;
      height: 1px;
    }
    100% {
      width: 100%;
      height: 100%;
      background: #fff;
    }
  }
  




/* stroke */
nav.stroke ul li a,
nav.fill ul li a {
  position: relative;
  font-family: 'Spectral', serif !important;
  font-size: 18px !important;
}
nav.stroke ul li a:after,
nav.fill ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: ".";
  text-decoration: none;
  color: transparent;
  background: #fff;
  height: 1px;
}
nav.stroke ul li a:hover:after {
  width: 100%;
}

nav.fill ul li a {
  transition: all 2s;
}

nav.fill ul li a:after {
  text-align: left;
  content: ".";
  margin: 0;
  opacity: 0;
}
nav.fill ul li a:hover {
  color: #fff;
  z-index: 1;
}
nav.fill ul li a:hover:after {
  z-index: -10;
  animation: fill 1s forwards;
  -webkit-animation: fill 1s forwards;
  -moz-animation: fill 1s forwards;
  opacity: 1;
}



.containerXlarge{
  max-width: 1300px;
  text-align: center;
}




.mediumContainerLarge{
  width:1000px
}

  .greenBg{
    background-color: #3bcbee;
    padding: 15px;
    border-radius:5px;
  }

  .greenBg p{

    text-color:#ffffff;  
  }

  .blue{
    background-color: #3bcbee;
  }

  .blueText{
    color: #0f6a76;
  }

.blueBg{
  background-color: #0f6a76;
  padding: 15px;
  border-radius:10px;
}

.orangeBg{
  background-color: #FA9917;
  padding: 15px;
  border-radius:10px;
}


.greenText{
  color: #3bcbee
}

.deepBlue{
  color: #0f6a76
}



  .bold{
    font-weight: 600
  }
  
  
  
  .green{
    background-color: #3bcbee;
  }
  
  .lightBlueBg{
    background-color: #FAF4FF;

  }
  .heroBg{
   
    background-image:linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../../images/heroBg.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 85vh;
  }

  
  
  

  .withPadding{
    padding: 60px 60px 60px 0
   
  }

  .withPaddingSmall{
    padding: 30px 30px 30px 0 
  }


.withPadRightLeft{
  padding-right: 20px;
  padding-left: 20px;
}



.withpaddingrightLarge{
  padding-right: 100px;
}




.withPaddingToCenterSubText{
  padding-right: 300px;
  padding-left: 300px;
}

.paddingTop{
  padding-top: 70px
}


  .paddingRightHeader{
    
  }

  

  .marginTopSmall{
      margin-top: 15px
  }

  .normalPaddingLR{
    padding-left: 60px;
    padding-right: 60px;
}

.smallText{
  font-size: 14px;
  line-height: 26px;
}

  .smallTextTopHeader{
    margin-bottom: -30px;
    color:#828282;
    text-transform: uppercase;
  }

.imgHeader{
  font-size: 16px;
  font-weight: 700;
}


.checkIcon{
  width:40px;
  position:relative;
  right:-20px;

}

.checkIconHome{
  width:30px;
  position:relative;
  right:-20px;
}
  

.teamImg{
  width: 350px;
}
  .headerBold{
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    padding-top: 30px;
  }
  
  .teamText{
    width: 700px;
    margin-top:20px;
  }
  
  
  .bIcon .headerBold{
    color: #fff;
    margin-top: -20px;
  }

  .bIcon_dark{
    color: #4e4e4e
  }
  
  
  
  
 
  
    .light{
      color:#fff;
    }


  
  
  
  
 
  .header1.withPaddingLarge{
    font-weight: 800;
  }
  
  
  
  
  .withPaddingLarge{
    
    padding-left: 180px;
    padding-right: 180px; 
    text-align: center;
  }

  .withPaddingXLarge{
    padding-left: 220px;
    padding-right: 220px; 
    
  }

  .centerTextDiv{
    width:600px;
    background-color: #ffdd00;
  }





  .withMarginLarge{
    margin-left: 120px;
    margin-right: 120px;
  }

  
  .icon{
    width:70px;
  }

.iconBig{
  width:130px;
}

  
  .iconCheck {
    width:45px;
  }


  .iconSecure{
    width: 40px;
  }
  
  .iconCheckT {
    width:39px !important;
  }



  .iconHeader{
    font-weight:500;
    font-size: 18px;
    padding-top: 5px;
  }

  .iconHeaderBold{
    font-weight:800;
    font-size: 16px;
    padding-top: 5px;
    letter-spacing: 0.6px;
  }

  .iconHeaderSmall{
    font-weight:600;
    font-size: 16px;
  }  
  
  .iconParagraph{
    font-size:16px;
    margin-top: 4px;
  }

.borderRadius{
  border-radius: 10px;
}



  .iconParagraphSmall{
    font-size:13px;
    line-height: 22px;
    margin-top: -4px;
   
  }
  
  
  
  .iconTextContainer{
    max-width: 400px;
  }
  
  .mVContainer{
    height: auto;
    background-color: #fff;
    margin-top: 30px;
    border-radius: 10px;
    padding: 30px;
    margin: 10px;
  
  }
  
  
  
  
  
  .subTitleText{
    padding-right: 80px;
    color: #fff;
    margin-top:-40px;
  }
  
.subText{
  font-size: 16px;
  line-height: 30px;
  
}


  
  .single-btn{
  width:100px;
  }
  
  
  .btn{
    background-color: #3bcbee;
    padding: 10px 30px 10px 30px;
    border-radius: 30px;
    color:#fff;
    text-decoration: none;
  }

  .btnCustom {
    background-color: #3bcbee;
    padding: 10px 30px 10px 30px;
    border-radius: 30px;
    color:#fff;
    text-decoration: none;
    width: 120px
  }
  
  .btn-light{
    background-color: #fff;
    padding: 10px 30px 10px 30px;
    border-radius: 30px;
    color:#000;
  }
  
  
  .btn-ghost-light{
    border: 1px solid #fff;
    padding: 10px 30px 10px 30px;
    border-radius: 30px;
    color:#fff;
  }
  
  .btn-ghost{
    border: 1px solid #3bcbee;
    padding: 10px 30px 10px 30px;
    border-radius: 30px;
    color:#4e4e4e;
  }

  .btn:hover{
        
      text-decoration: none !important;
  }

 


  .spacingDiv{
    height: 200px;
  }
  
  
  .grayBg{
    background-color: #faf4ff;
    padding-bottom: 60px;
    padding-top: 60px;
    
  }

  .headerTestyBg{
    background-color: #fff;
    
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	padding:20px;
	border-bottom-left-radius:20px;
	border-top-right-radius:20px
  }
  
  
  .testimonialBg{
    height: 125vh;
    background-color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    margin-top: -60px;
    border-radius: 10px;
    margin-bottom: 100px;
  }
  
  .singleTestifyContainer{
     max-height: 500px ;
     
    
    
  }
  
  
  .testifyTextBg{
    background-color: #faf4ff;
    height: auto;
    width: 90%;
    margin-top: -50px;
    padding-top:70px;
    border-radius: 6px;
   
  }
  
  
  
  .testifyImage{
    width:118px;
    position: relative;
    left:-20px;
  }
  
  .testifyText {
    
  padding:20px;
    font-size: 14px;
    text-align: center;
   
  }
  
  .testifyHeader h4{
    font-weight: bold;
    font-size:16px;
    padding-top: 10px;
  }
  
  
  .testifyLocation h6{
   font-size:14px;
   font-weight:400;
   margin-top: -15px;
  }
  
  .testyDesc p{
     padding-left: 120px;
     padding-right: 120px;
  
  }
  
  .smallTopHeading{
    font: size 18px;
    font-weight: 600;
    letter-spacing:3px ;
    color:#828282;
    margin-bottom: -40px;
  }
  
  .ratings{
    padding-top: 30px;
  }
  
  .lightText{
    color: #fff
  }
  
  .statsBg{
    background-image:linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../../images/aboutCyberTeckAcademy.png");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  
  height: 100vh;
  }
  
  .resultHeader{
    font-size: 60px;
    color: #fff;
    margin-top: 10px;
  }
  
  .statsImg{
    width:180px;
    position: relative;
    bottom: -15px;
  }
  
  .statsContainer{
   padding-right:120px;
   padding-left:120px
  }


/*******ToolBox ateleirs*******/
.introContainer{
  background-color: #fff;
  height: 160px;
  margin-top: -120px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	padding:20px;
	border-bottom-left-radius:20px;
	border-top-right-radius:20px

}
.boxShadow{
  
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding:20px;
    border-bottom-left-radius:20px;
    border-top-right-radius:20px
}

.moveLeftDiv{
  position: relative;
  left: -90px;
  top:90px;
  background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding:20px;
    border-bottom-left-radius:20px;
    border-top-right-radius:20px
}

.blurbText{
  font-size: 16px !important;
  line-height: 22px !important;
}

.line{
  width:120px;
  background-color: #3FC6EB;
  height: 2px;
  margin-top: -2px;
}

.teamImg{
  position: relative;
  top:0;
 
}

.iconx{
  color: #3bcbee !important;
}

.ctaBg2{
  background-color: #3FC6EB;
  padding: 50px;
}

.teamSubHeader{
  font-size:40px !important;
  font-weight: 100 !important;
  
}

.teamBg{
  background-color: #fff;
  padding: 10px;
  position: relative;
  top:140px;
}

.teamTitle{
  font-size: 14px;
  font-weight: 100 !important;
}

.teamDesc{
  position: relative;
  right:-90px;
  margin-top: 25px;
}

.briefImg{
  width:20px;
}

.socialIcons{
  color:#fff !important
}
.line{
  width:90px;
  height:6px
}

.quality{
  width:60px
}

.box_shadow_white{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	padding:20px;
	border-bottom-left-radius:20px;
	border-top-right-radius:20px;
  background-color: #fff;
}

.borderWhite{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	padding:20px;
	
  background-color: #fff;
}

.homeIcon1{
  width:49px;
  margin-right: 20px;;
}


.blueText{
  color: #3bcbee;
}


.blueBg{
  background-color: #3bcbee;
}

.text36{
  font-size: 36px !important;
  line-height: 48px !important;
}

/******SECOND HOME PAGE****/
.ctaHero a{
  color:#3bcbee;
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
}

.ctaBtn{
  background-color: #3bcbee;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	padding:20px;
  border-radius: 10px;
  padding: 15px 40px 15px 40px;
  color: #fff !important;
  font-size: 16px;
  font-weight: 600;
}

.ctaBtn, a{
  text-decoration: none;
  color:#fff
}

.ceoHomeImg{
  background-color: #3bcbee;
 height: 500px;
  }

  .borderRec{
    border: 20px solid #3bcbee;
    position: relative;
    left:290px !important;
    padding: 60px;
    width:900px !important;
    border-radius: 10px;
    padding-bottom: 30px;
  }

  .sadam{
    position: relative;
    top:-460px;
    right:-50px
  }

  .grayBgNew{
    background-color: #f2f2f2;
   height: 900px;
  }

  .fourthUpPane{
    position: relative;
    top:-320px;
  }

  .fifthUpPane{
    position: relative;
    top:-130px;
  }

  .leftDark{
    background-color: #333230;
    height: 400px;
    width:600px;
    
    position: relative;
    top:-60px;
  }

  .grayText{
    color:#333230 !important
  }

  .Grid3{
    position: relative;
    top:-190px;
  }


  .dots{
  }

  .border{
    border-radius: 10px;
  }

  .projectLink, a{
text-decoration: none !important;
color: #3bcbee !important;
  }

  .historyIcon{
    width:70px
  }

  .customTeamWidth{
    width: 1400px;
    
    margin: 0 auto;
  }

.customPortfolioContainer{
  width: 1400px;
  margin: 0 auto !important;
}

















/***IMAGE HOVER****/

figure.snip1104 {
  font-family: 'Raleway', Arial, sans-serif;
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-width: 100%;
  max-width: 100%;
  max-height: 400px;
  width: 100%;
  background: #000000;
  color: #ffffff;
  text-align: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

figure.snip1104 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

figure.snip1104 img {
  max-width: 100%;
  position: relative;
  opacity: 0.4;
}

figure.snip1104 figcaption {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

figure.snip1104 h4 {
  position: absolute;
  left: 40px;
  right: 40px;
  display: inline-block;
  background: #000000;
  padding: 12px 5px;
  margin: 0;
  top: 75%;
  color:#fff;
  text-transform: uppercase;
  font-weight: 400;
}

figure.snip1104 h4 span {
  font-weight: 800;
}

figure.snip1104:before {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  content: '';
  background: #ffffff;
  position: absolute;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

figure.snip1104 a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 1;
}

figure.snip1104.blue {
  background: #123851;
}

figure.snip1104.blue h2 {
  background: #0a212f;
}

figure.snip1104.red {
  background: #000;
}

figure.snip1104.red h2 {
  background: #000;
  color:#fff;
}

figure.snip1104.yellow {
  background: #7f5006;
}

figure.snip1104.yellow h2 {
  background: #583804;
}

figure.snip1104:hover img,
figure.snip1104.hover img {
  opacity: 1;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

figure.snip1104:hover h4,
figure.snip1104.hover h4 {
  -webkit-transform: skew(-10deg) rotate(-10deg) translate(-150%, -50%);
  transform: skew(-10deg) rotate(-10deg) translate(-150%, -50%);
}

figure.snip1104:hover:before,
figure.snip1104.hover:before {
  -webkit-transform: rotate(110deg) translateY(-150%);
  transform: rotate(110deg) translateY(-150%);
}







  
  /***IMAVE UP & DOWN MOTION****/
  img.vert-move {
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
  }
  img.vert-move {
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
  }
  @-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-70px); }
  }
  @keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-70px); }
  }
  .imgDiv{
  width:100%
  }
